import * as bootstrap from 'bootstrap'

var AdvertShown = 0;

function reveal() {
    var reveals = document.querySelectorAll(".reveal");
  
    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      } else {
        reveals[i].classList.remove("active");
      }
    }
  }
  
  window.addEventListener("scroll", reveal);

  function showAdvert() {
    var reveals = document.querySelectorAll("#advert");
  
    const ToastEl = document.getElementById('advert')
    ToastEl.addEventListener('hidden.bs.toast', () => {
     AdvertShown++; 
    })
  


    for (var i = 0; i < reveals.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 150;
  
      if (elementTop < windowHeight - elementVisible && AdvertShown == 0) {
        reveals[i].classList.add("show");
       } 
    }
  }
  
  setTimeout(function() { showAdvert(); }, 10000);